<template>
  <svg viewBox="0 0 140 157" xmlns="http://www.w3.org/2000/svg">
    <g fill="currentColor">
      <path
        d="m132.442 62.286-40.906-25.359-14.192 6.782 43.102 26.7c3.733 2.312 5.949 6.296 5.949 10.668v22.463c0 4.411-2.352 8.531-6.144 10.785l-45.201 26.895c-3.441 2.06-7.757 2.118-11.276.175l-.7-.389-12.618 6.257 12.035 7.54c2.333 1.457 4.977 2.196 7.621 2.196 2.527 0 5.035-.661 7.31-2.002l54.729-32.393c4.724-2.799 7.66-7.948 7.66-13.428v-33.618c.019-5.441-2.741-10.416-7.368-13.272z"
      />
      <path
        d="m107.959 108.303c4.88-2.76 7.913-7.968 7.913-13.584v-20.249l-12.676-6.627v21.162c0 4.508-2.43 8.686-6.357 10.921l-46.154 26.195c-3.811 2.177-8.574 2.177-12.384.02l-19.986-11.291c-3.013-1.71-4.88-4.897-4.88-8.355v-.875l-13.434-7.987v14.594c0 4.567 2.469 8.784 6.455 11.018l29.979 16.906c2.372 1.341 5.016 2.002 7.66 2.002 2.664 0 5.327-.68 7.699-2.021l56.167-31.83z"
      />
      <path
        d="m7.563 94.544 42.208 25.378 13.376-6.84-43.705-26.661c-3.713-2.274-6.027-6.374-6.027-10.726v-22.309c0-4.45 2.391-8.628 6.26-10.863l46.29-26.875c3.674-2.138 8.127-2.274 11.918-.369l1.069.544 12.19-6.277-11.84-7.248c-4.899-2.993-11.023-3.07-16-.194l-55.525 32.181c-4.802 2.779-7.777 7.948-7.777 13.505v33.405c0 5.422 2.897 10.551 7.563 13.35z"
      />
      <path
        d="m131.878 32.75-28.482-16.051c-4.743-2.682-10.634-2.662-15.378.02l-56.148 31.811c-4.88 2.76-7.913 7.967-7.913 13.583v20.248l12.676 6.626v-21.142c0-4.508 2.43-8.686 6.358-10.921l46.154-26.195c3.811-2.176 8.574-2.176 12.384-.019l19.986 11.29c3.013 1.71 4.88 4.917 4.88 8.356v.874l13.434 7.987v-12.864c0-5.635-3.033-10.843-7.952-13.603z"
      />
    </g>
  </svg>
</template>
